import React, { useEffect, useRef, useState } from 'react';
import { Column, LoadingSpinner, ErrorBox, Pagination, CheckboxItem } from '@mattilsynet/mt-ui';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../reducers/types';
import { VirksomhetCardItem } from '../../components/virksomhet-card-item';
import { ADRESSE_ERROR, IVirksomhetItem, IVirksomhetTilsynsobjekt } from '../../ducks/virksomhet-search/types';
import { virksomhetSearch, VirksomhetSearchInputWrapper } from '../../ducks/virksomhet-search';
import { IAktivitet, IMidlertidigTilsynsobjektVirksomhet } from '../../ducks/kvittering/types';
import { useTypedSelector } from '../../common/custom-hooks';
import { ModalWrapper } from '../../components/modal-wrapper';
import { ITilsynsobjekt } from '../../features/tilsynsobjekter/types';
import { modalContentPadding } from '../../components/modal-buttons-container/constants';
import { ModalButtonsContainer } from '../../components/modal-buttons-container';
import { ModalButton } from '../../components/modal-button';
import { useKvitteringId } from '../../common/kvittering-context';
import { useRemoveTilsynsobjekt, useTilsynsobjekter, useAddTilsynsobjekt } from '../../features/tilsynsobjekter';
import { useCreateKvitteringMutation } from '../../features/kvitteringer';
import { kvitteringQueryHelpers } from '../../features/kvitteringer/queries/helpers';
import { useAddMidlertidigTilsynsobjekt } from '../../features/midlertidig-tilsynsobjekter';
import { IMidlertidigTilsynsobjektPayload } from '../../features/midlertidig-tilsynsobjekter/types';
import { TilsynsobjektDeleteModal } from '../tilsynsobjekt-delete-modal';
interface IVirksomhetModalProps {
  isOpen: boolean;
  onCancel: any;
  initialSearch?: string;
  onClickMidlertidigTilsynsobjekt: (navn, nummer) => void;
  midlertidigTilsynsobjektAktivitet?: IAktivitet;
  midlertidigTilsynsobjektVirksomhet?: IMidlertidigTilsynsobjektVirksomhet;
  onResetMidlertidigTilsynsojekt: () => void;
  onUnselectMidlertidigTilsynsobjekt: (tilsynsobjekt) => void;
  unselectedMidlertidigTilsynsobjekt?: ITilsynsobjekt;
}
export const VirksomhetModal = ({
  isOpen,
  onCancel,
  initialSearch,
  onClickMidlertidigTilsynsobjekt,
  midlertidigTilsynsobjektAktivitet,
  midlertidigTilsynsobjektVirksomhet,
  onResetMidlertidigTilsynsojekt,
  onUnselectMidlertidigTilsynsobjekt,
  unselectedMidlertidigTilsynsobjekt
}: IVirksomhetModalProps) => {
  const dispatch = useDispatch();
  const kvitteringId = useKvitteringId();
  const virksomhetSearchState = useSelector((state: IStoreState) => state.virksomhetSearch);
  const {
    data
  } = useTilsynsobjekter(kvitteringId);
  const {
    mutate: createTilsynsobjekt
  } = useAddTilsynsobjekt();
  const {
    mutate: createMidertidigTilsynsobjekt
  } = useAddMidlertidigTilsynsobjekt();
  const {
    mutate: removeTilsynsobjekt
  } = useRemoveTilsynsobjekt(kvitteringId);
  const {
    mutateAsync: createKvittering
  } = useCreateKvitteringMutation();
  const tilsynsobjekter = data ?? [];
  const [includeEmptyVirksomhet, setIncludeEmptyVirksomhet] = useState(false);
  const [isDeleteTilsynsobjektModalOpen, setIsDeleteTilsynsobjektModalOpen] = useState(false);
  const [tilsynsobjektWithKontrollpunktToDelete, setTilsynsobjektWithKontrollpunktToDelete] = useState<ITilsynsobjekt | undefined>();
  const onClickSearch = (searchValue?: string) => {
    dispatch(virksomhetSearch.actions.search({
      search: searchValue,
      size: 50,
      expand: 'tilsynsobjekter',
      sort: 'navn',
      includeEmptyVirksomhet: includeEmptyVirksomhet
    }));
  };
  const onPagination = (pageNumber: number) => {
    dispatch(virksomhetSearch.actions.search({
      ...virksomhetSearchState.queries,
      page: pageNumber,
      includeEmptyVirksomhet: includeEmptyVirksomhet
    }));
  };
  const addMidlertidigTilsynsobjekt = (kvitteringId: string, tilsynsobjekt: IMidlertidigTilsynsobjektPayload, isSelected: boolean, orgNr: string) => {
    createMidertidigTilsynsobjekt({
      kvitteringId,
      tilsynsobjekt,
      isSelected,
      orgNr
    }, {
      onSettled: () => {
        kvitteringQueryHelpers.invalidateKvittering(kvitteringId);
      }
    });
  };
  const addTilsynsobjekt = (kvitteringId: string, tilsynsobjekt: IVirksomhetTilsynsobjekt, isSelected: boolean, orgNr: string) => {
    createTilsynsobjekt({
      kvitteringId,
      tilsynsobjekt,
      isSelected,
      orgNr
    }, {
      onSettled: () => {
        kvitteringQueryHelpers.invalidateKvittering(kvitteringId);
      }
    });
  };
  const isKvitteringCreated = useTypedSelector((state: IStoreState) => state.kvittering.selectedKvittering.data.id);

  // to update isKvitteringCreated in onAddOrUpdateKvittering retries
  const isKvitteringCreatedRef = useRef(!!isKvitteringCreated);
  useEffect(() => {
    isKvitteringCreatedRef.current = !!isKvitteringCreated;
  }, [isKvitteringCreated]);
  const onOpenTilsynsobjektConfirmDelteModal = (tilsynsobjekt: ITilsynsobjekt) => {
    setIsDeleteTilsynsobjektModalOpen(true);
    setTilsynsobjektWithKontrollpunktToDelete(tilsynsobjekt);
  };
  const onSelectTilsynsobjekt = async (virksomhetTilsynsobjekt: IVirksomhetTilsynsobjekt, isSelected: boolean, orgNr: string) => {
    if (isSelected && !kvitteringId) {
      const kvitteringId = await createKvittering({
        virksomhetTilsynsobjekt,
        isSelected,
        orgNr
      });
      addTilsynsobjekt(kvitteringId, virksomhetTilsynsobjekt, isSelected, orgNr);
    }
    if (isSelected && kvitteringId) {
      if (tilsynsobjekter) {
        addTilsynsobjekt(kvitteringId, virksomhetTilsynsobjekt, isSelected, orgNr);
      }
    }
    if (!isSelected) {
      const selectedTilsynsobjekt = tilsynsobjekter?.find((tilsynsobjekt: ITilsynsobjekt) => tilsynsobjekt.tilsynsobjektId === virksomhetTilsynsobjekt.idstring);
      if (!selectedTilsynsobjekt?.midlertidig && !tilsynsobjektWithKontrollpunktToDelete) {
        removeTilsynsobjekt({
          kvitteringId,
          tilsynsobjekt: selectedTilsynsobjekt ?? {} as ITilsynsobjekt
        });
      }
    }
  };
  const onSelectMidlertidigTilsynsobjekt = (virksomhetTilsynsobjekt, isSelected, orgNr) => {
    const selectedTilsynsobjekt = tilsynsobjekter?.find((tilsynsobjekt: ITilsynsobjekt) => tilsynsobjekt.navn === virksomhetTilsynsobjekt.navn && tilsynsobjekt.virksomhetsNummer === virksomhetTilsynsobjekt.virksomhetsNummer && !!tilsynsobjekt.midlertidig);
    if (isSelected) {
      if (unselectedMidlertidigTilsynsobjekt) {
        onUnselectMidlertidigTilsynsobjekt(null);
      }
      if (!selectedTilsynsobjekt) {
        addMidlertidigTilsynsobjekt(kvitteringId, virksomhetTilsynsobjekt, isSelected, orgNr);
      }
    } else {
      onUnselectMidlertidigTilsynsobjekt(selectedTilsynsobjekt);
    }
  };
  const deleteMidlertidigTilsynsobjekt = () => {
    if (unselectedMidlertidigTilsynsobjekt) {
      removeTilsynsobjekt({
        kvitteringId,
        tilsynsobjekt: unselectedMidlertidigTilsynsobjekt
      });
      onUnselectMidlertidigTilsynsobjekt(null);
    }
  };
  const onCancelVirksomhetModal = () => {
    deleteMidlertidigTilsynsobjekt();
    onCancel();
  };
  useEffect(() => {
    if (isOpen) {
      initialSearch ? onClickSearch(initialSearch) : dispatch(virksomhetSearch.actions.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  const renderList = () => {
    const {
      result,
      loaded
    } = virksomhetSearchState;
    const virksomhetList = result && result.virksomhetList ? result.virksomhetList : [];
    if (virksomhetSearchState.loading) return <LoadingSpinner title="Henter innhold..." />;
    if (virksomhetSearchState.error && virksomhetSearchState.error !== ADRESSE_ERROR) {
      const searchValue = virksomhetSearchState.queries?.search;
      return <ErrorBox errorText="Kunne ikke hente innhold.." errorActionText="Prøv igjen.." errorAction={() => onClickSearch(searchValue)} />;
    }
    if (!virksomhetList.length && loaded) {
      return <ErrorBox errorText="Ingen treff" />;
    }
    return virksomhetList.map((item: IVirksomhetItem) => <VirksomhetCardItem key={item.navn} onSelectTilsynsobjekt={onSelectTilsynsobjekt} onSelectMidlertidigTilsynsobjekt={onSelectMidlertidigTilsynsobjekt} onClickMidlertidigTilsynsobjekt={onClickMidlertidigTilsynsobjekt} item={item} midlertidigTilsynsobjektAktivitet={midlertidigTilsynsobjektAktivitet} midlertidigTilsynsobjektVirksomhet={midlertidigTilsynsobjektVirksomhet} onResetMidlertidigTilsynsojekt={onResetMidlertidigTilsynsojekt} unselectedMidlertidigTilsynsobjekt={unselectedMidlertidigTilsynsobjekt} openTilsynsobjektConfirmDeleteModal={onOpenTilsynsobjektConfirmDelteModal} />);
  };
  return <>
      <ModalWrapper alignTop fullscreenMobile isOpen={isOpen} onCancel={onCancelVirksomhetModal} title="Søk etter tilsynsobjekt" data-sentry-element="ModalWrapper" data-sentry-source-file="index.tsx">
        <Column className="virksomhet-modal-content" fluid spacing={2} margin={[0.25, 0]} padding={modalContentPadding} data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <VirksomhetSearchInputWrapper initialSearchValue={initialSearch} searchLoading={virksomhetSearchState?.loading} handleOnSubmit={onClickSearch} autoFocus={true} data-sentry-element="VirksomhetSearchInputWrapper" data-sentry-source-file="index.tsx">
            <p>
              Du kan søke etter tilsynsobjekt ved å bruke virksomhetsnavn,
              org.nr. eller tilsynsobjektnavn.
            </p>
          </VirksomhetSearchInputWrapper>
          <CheckboxItem onClick={() => setIncludeEmptyVirksomhet(!includeEmptyVirksomhet)} selected={includeEmptyVirksomhet} data-sentry-element="CheckboxItem" data-sentry-source-file="index.tsx">
            <p>Inkluder tomme virksomhetsmapper</p>
          </CheckboxItem>
          {renderList()}

          <Pagination page={virksomhetSearchState.page} onPaginationClick={onPagination} data-sentry-element="Pagination" data-sentry-source-file="index.tsx" />
        </Column>
        <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
          <ModalButton onClick={onCancelVirksomhetModal} secondary={!tilsynsobjekter?.length} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
            {tilsynsobjekter?.length ? 'Ferdig' : 'Lukk'}
          </ModalButton>
        </ModalButtonsContainer>
      </ModalWrapper>
      {isDeleteTilsynsobjektModalOpen && <TilsynsobjektDeleteModal isDeleteModalOpen={isDeleteTilsynsobjektModalOpen} onDelete={() => {
      removeTilsynsobjekt({
        kvitteringId,
        tilsynsobjekt: tilsynsobjektWithKontrollpunktToDelete ?? {} as ITilsynsobjekt
      });
      setIsDeleteTilsynsobjektModalOpen(false);
    }} tilsynsobjekt={tilsynsobjektWithKontrollpunktToDelete ?? {} as ITilsynsobjekt} onCancel={() => {
      setIsDeleteTilsynsobjektModalOpen(false);
      setTilsynsobjektWithKontrollpunktToDelete(undefined);
    }} />}
    </>;
};