import React from 'react';
import { IStoreState } from '../../../reducers/types';
import { useSelector, useDispatch } from 'react-redux';
import { Column, Row, LoadingSpinner, Text, ContrastBox } from '@mattilsynet/mt-ui';
import '../style.css';
import { IKvitteringData } from '../../../ducks/kvittering/types';
import { kvitteringActions } from '../../../ducks/kvittering/actions';
import { MobilenumberItem } from '../../../components/mobilenumber-item';
import { AndreDeltakere, MattilsynetDeltakere, VirksomhetDeltakere } from '../../../features/deltakere';
import { InformationBox } from '../../../components/information-box';
import BreakableAnchor from '../../../components/breakable-anchor';
export const DeltakereSlide = () => {
  const dispatch = useDispatch();
  const currentKvittering = useSelector((state: IStoreState) => state.kvittering.selectedKvittering.data);
  const isLoading = useSelector((state: IStoreState) => state.kvittering.loading);
  const onUpdateMobilnummer = (mobilnummer?: string) => {
    dispatch(kvitteringActions.updateCurrentKvittering({
      mobilForUndersoekelse: mobilnummer?.replace(/\s/g, '')
    }));
  };
  const renderContent = () => {
    if (isLoading) {
      return <Row center>
          <LoadingSpinner title={'Laster deltagerer...'} small />
        </Row>;
    }
    return <>
        <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="deltakere-slide.tsx">
          <VirksomhetDeltakere data-sentry-element="VirksomhetDeltakere" data-sentry-source-file="deltakere-slide.tsx" />
        </ContrastBox>

        <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="deltakere-slide.tsx">
          <MattilsynetDeltakere data-sentry-element="MattilsynetDeltakere" data-sentry-source-file="deltakere-slide.tsx" />
        </ContrastBox>

        <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="deltakere-slide.tsx">
          <AndreDeltakere data-sentry-element="AndreDeltakere" data-sentry-source-file="deltakere-slide.tsx" />
        </ContrastBox>

        <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="deltakere-slide.tsx">
          <Column data-sentry-element="Column" data-sentry-source-file="deltakere-slide.tsx">
            <MobilenumberItem onUpdateKvittering={(kvittering: IKvitteringData) => onUpdateMobilnummer(kvittering.mobilForUndersoekelse)} kvittering={currentKvittering} data-sentry-element="MobilenumberItem" data-sentry-source-file="deltakere-slide.tsx" />
            <Row data-sentry-element="Row" data-sentry-source-file="deltakere-slide.tsx">
              <Text as="p" weight="regular" data-sentry-element="Text" data-sentry-source-file="deltakere-slide.tsx">
                Telefonnummeret vil ikke vises i tilsynskvitteringen.
              </Text>
            </Row>
            <Row data-sentry-element="Row" data-sentry-source-file="deltakere-slide.tsx">
              <InformationBox data-sentry-element="InformationBox" data-sentry-source-file="deltakere-slide.tsx">
                <Text data-sentry-element="Text" data-sentry-source-file="deltakere-slide.tsx">
                  Brukerundersøkelsen sendes ut automatisk når
                  Tilsynskvitteringen ferdigstilles. For bruksanvisning se{' '}
                  <BreakableAnchor href="https://mattilsynet.pureservice.com/#/faqs/faq/307" data-sentry-element="BreakableAnchor" data-sentry-source-file="deltakere-slide.tsx">
                    https://mattilsynet.pureservice.com/#/faqs/faq/307
                  </BreakableAnchor>
                </Text>
              </InformationBox>
            </Row>
          </Column>
        </ContrastBox>
      </>;
  };
  return <Column spacing={3} fluid key={2} data-sentry-element="Column" data-sentry-component="DeltakereSlide" data-sentry-source-file="deltakere-slide.tsx">
      <Row className="title" data-sentry-element="Row" data-sentry-source-file="deltakere-slide.tsx">
        <h1>Deltakere</h1>
      </Row>
      {renderContent()}
    </Column>;
};